<template>
  <main class="flex-1">
    <PageHeading>Clients</PageHeading>

    <section class="p-8 max-w-screen-6xl">
      <BaseTable :data="data" />
    </section>
  </main>
</template>

<script>
const data = require('@/data/sample.json')

export default {
  name: 'ClientsView',

  data() {
    return {
      data,
    }
  },
}
</script>
